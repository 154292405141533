<template>
  <div class="ac_ap-list-container">
    <a-spin :spinning="spinning">
    <div style="margin-bottom: 16px">
      <create-button v-permission="{action: 'base.network_device.create', effect: 'disabled'}" @click="$refs.inputDrawer.show()">
      </create-button>
      <a-input-search
        class="ac-ap-search"
        style="float: right"
        placeholder="请输入搜索关键字"
        @search="search"
      ></a-input-search>
    </div>
    <div class="module-container">
      <div class="module-header" style="margin-bottom: 24px">AC</div>

      <template v-if="dataSource.length && !spinning">
        <a-row  v-if="dataSource.length" :gutter="16">
          <a-col
            v-for="item in dataSource"
            :key="item.id"
            :md="12"
            :lg="8"
            :xxl="6"
            style="margin-bottom: 16px"
          >
            <ac-card :detail="item" @ok="(v) => fetch(v)"></ac-card>
          </a-col>
        </a-row>
        <div style="height: 32px;margin: 8px 0 0;">
          <a-pagination
            :current="params.page"
            :page-size="params.page_size"
            :page-size-options="['12', '24', '36', '48']"
            :show-size-changer="true"
            :show-total="t => `共 ${t} 条`"
            :total="total"
            @change="
              page => {
                params.page = page
                fetch()
              }
            "
            @showSizeChange="
              (_, pageSize) => {
                params.page = 1
                params.page_size = pageSize
                fetch()
              }
            "
            style="float: right"
          ></a-pagination>
        </div>
      </template>
      <a-empty v-else-if="!dataSource.length && !spinning" style="margin: 40px 0 60px 0;"></a-empty>
    </div>
    </a-spin>

    <hardware-input-drawer
      ref="inputDrawer"
      source-type="network_device"
      device-type="access_controller"
      :isSimple="true"
      @ok="fetch(['add'])"
    ></hardware-input-drawer>
  </div>
</template>

<script>
import { getNetworkDeviceList, getNetworkDevice } from '@/api/network-device'
import { updateCurrentPage } from '@/utils'
import CreateButton from '@/components/button/CreateButton'

export default {
  name: 'ACAPList',
  components: {
    'ac-card': () => import('./modules/ACCard'),
    HardwareInputDrawer: () => import('@/components/drawer/HardwareInputDrawer'),
    CreateButton
  },
  data () {
    return {
      dataSource: [],
      params: {
        page: 1,
        page_size: 12
      },
      total: 0,
      spinning: false
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch (update) {
      if (!update) {
        this.spinning = true
        if (update) {
          this.params.page = updateCurrentPage(
            this.total,
            this.params.page,
            this.params.page_size
          )
        }
        const params = {
          page: this.params.page,
          page_size: this.params.page_size,
          device_type: 'access_controller'
        }
        if (this.params.name) params.name = this.params.name
        getNetworkDeviceList(params).then(res => {
          this.dataSource = res.data.data
          this.total = res.data.total
        }).finally(() => {
          this.spinning = false
        })
      } else {
        if (update[0] === 'edit' && update[1]) {
          const index = this.dataSource.findIndex(item => item.id === update[1].id)
          this.dataSource = this.dataSource.filter(item => item.id !== update[1].id)
          getNetworkDevice(update[1].id).then(res => {
            const data = res.data
            this.dataSource.splice(index, 0, data)
          })
        } else {
          const params = {
            page: this.params.page,
            page_size: this.params.page_size,
            device_type: 'access_controller'
          }
          if (this.params.name) params.name = this.params.name
          getNetworkDeviceList(params).then(res => {
            const data = res.data.data
            this.total = res.data.total
            if (update[0] === 'delete' && update[1]) {
              this.dataSource = this.dataSource.filter(item => item.id !== update[1].id)
              data.forEach(item => {
                if (this.dataSource.findIndex(v => v.id === item.id) === -1) this.dataSource.push(item)
              })
            } else if (update[0] === 'add') {
              data.forEach(item => {
                if (this.dataSource.findIndex(v => v.id === item.id) === -1) this.dataSource.unshift(item)
                if (this.dataSource.length > this.params.page_size) this.dataSource.pop()
              })
            }
          })
        }

        // if (update[1]) {
        //   const index = this.dataSource.findIndex(item => item.id === update[1].id)
        //   this.dataSource = this.dataSource.filter(item => item.id !== update[1].id)
        //   if (update[0] === 'delete') {
        //     this.params.page = updateCurrentPage(
        //       this.total,
        //       this.params.page,
        //       this.params.page_size
        //     )
        //     const params = {
        //       page: this.params.page,
        //       page_size: this.params.page_size,
        //       device_type: 'access_controller'
        //     }
        //     if (this.params.name) params.name = this.params.name
        //     getNetworkDeviceList(params).then(res => {
        //       const data = res.data.data
        //       this.total = res.data.total
        //       data.forEach(item => {
        //         if (this.dataSource.findIndex(v => v.id === item.id) === -1) this.dataSource.push(item)
        //       })
        //     })
        //   } else if (update[0] === 'edit') {
        //     getNetworkDevice(update[1].id).then(res => {
        //       const data = res.data
        //       this.dataSource.splice(index, 0, data)
        //     })
        //   }
        // } else if (update[0] === 'add') {
        //   const params = {
        //     page: this.params.page,
        //     page_size: this.params.page_size,
        //     device_type: 'access_controller'
        //   }
        //   if (this.params.name) params.name = this.params.name
        //   getNetworkDeviceList(params).then(res => {
        //     const data = res.data.data
        //     this.total = res.data.total
        //     data.forEach(item => {
        //       if (this.dataSource.findIndex(v => v.id === item.id) === -1) this.dataSource.unshift(item)
        //       if (this.dataSource.length > this.params.page_size) this.dataSource.pop()
        //     })
        //   })
        // }
      }
    },
    search (value) {
      this.params.name = value
      this.params.page = 1
      this.fetch()
    }
  }
}
</script>

<style lang="less">
.ac_ap-list-container {
  .ac-ap-search {
    width: 180px;
    .ant-input {
      background-color: #fff;
    }
  }
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px;
    min-height: 286px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  }
}
</style>
