<template>
  <a-button
    icon="plus"
    :type="type"
    :size="size"
    @click="e => $emit('click', e)"
  >
    {{ text }}
  </a-button>
</template>

<script>
export default {
  name: 'CreateButton',
  props: {
    text: {
      type: String,
      default: '创建'
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'default'
    }
  }
}
</script>
